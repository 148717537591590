export const IMAGE_INFO = [
  {
    type: "Photography",
    images: [
      {
        image: "/exhibits/雲彩戲水蓮天.jpg",
        introduction: "日月潭捕捉到的水連天與水、蓮、天",
        title: "雲彩戲水蓮天",
        author: "王倫 教授",
      },
      {
        image: "/exhibits/雲彩戲水蓮天-2.jpg",
        introduction: "日月潭捕捉到的水連天與水、蓮、天",
        title: "雲彩戲水蓮天-2",
        author: "王倫 教授",
      },
      {
        image: "/exhibits/雲彩戲水蓮天-3.jpg",
        introduction: "日月潭捕捉到的水連天與水、蓮、天",
        title: "雲彩戲水蓮天-3",
        author: "王倫 教授",
      },
      {
        image: "/exhibits/Ragnarok.jpg",
        introduction: "波斯貓化身為神祇，上演著絨毛版的神話大戲。 ",
        title: "Ragnarok",
        author: "王瑋",
      },
      {
        image: "/exhibits/天堂之心.jpg",
        introduction:
          "站在教堂中心，仰望著教堂屋頂，\n彷彿置身於一座天堂之中。\n屋頂的對稱設計展現了人類建築之美，\n它如同一對展翅飛翔的天使，在青天之上舞動。\n畫面的協和感讓人感受到內心的平靜與寧靜，\n彷彿聽見了宇宙的樂章在此奏響。\n這個畫面散發著一種神聖的光芒，\n讓人感受到心靈的淨化與啟發。",
        title: "天堂之心",
        author: "林桓鈺",
      },
      {
        image: "/exhibits/林瀑幽韻.jpg",
        introduction:
          "在茂密的針葉林中，一道清澈的小瀑布躍然眼前\n瀑布如一條白色的絲帶，\n從山間飄蕩而下，如音符般跳動著。\n周圍的針葉樹綠意盎然，\n瀑布的潺潺水聲在林間迴盪。\n這片景色彷彿被遺忘在世界的一隅，靜謐而美麗。\n瀑布和林間的交融，勾勒出一幅幽靜而深遠的畫面，\n讓人彷彿置身於童話般的仙境之中。",
        title: "林瀑幽韻",
        author: "林桓鈺",
      },
      {
        image: "/exhibits/觸動天際.jpg",
        introduction:
          "凝視著天空，一架戰鬥機呼嘯而過，驚鴻一瞥。\n霎時，心潮澎湃，感動萬分。\n飛機穿越天際，勾勒出人類勇氣與智慧。\n這瞬間，我們與宇宙相連，感受生命的脆弱與偉大。\n這不僅是一次遭遇，更是心靈的觸動，\n讓我們對未來充滿信心與期待。",
        title: "觸動天際",
        author: "林桓鈺",
      },
      {
        image: "/exhibits/記憶中的瞬間.jpg",
        introduction:
          "記憶中的瞬間，\n可能是模糊的、可能是缺少脈絡的、\n可能是充滿紋理的、也可能是單調的。\n無論是哪個瞬間都已逝去，\n但記憶裡餘下的，就是我們所擁有的。",
        title: "記憶中的瞬間",
        author: "楊詠翔",
      },
      {
        image: "/exhibits/隅-1.jpg",
        introduction:
          "不是角落，但是是城市的角落\n底片：foma400 翻拍：Canon 650D",
        title: "隅-1",
        author: "陳俊瑋",
      },
      {
        image: "/exhibits/隅-2.jpg",
        introduction:
          "不是角落，但是是城市的角落\n底片：foma400 翻拍：Canon 650D",
        title: "隅-2",
        author: "陳俊瑋",
      },
      {
        image: "/exhibits/隅-3.jpg",
        introduction:
          "不是角落，但是是城市的角落\n底片：foma400 翻拍：Canon 650D",
        title: "隅-3",
        author: "陳俊瑋",
      },
      {
        image: "/exhibits/老家-1.jpg",
        introduction:
          "本次展覽的四張照片為2024過年回去台南老家時所拍攝的作品，這幾年回去老家和外公外婆見面的頻率越來越低，因此格外珍惜和期待能夠相處的時光。\n\n這趟旅程實際上拍攝了一系列的照片，而之所以選擇此四張照片作為展出，是因為當下彷彿有一股難以名狀的能量指引我按下快門，我想是屬於台南老家的氣味、溫度、光影使我掉進了回憶的漩窩，想起了小時候在此處生長的點點滴滴，這些經驗思緒對我的人生是正向且溫暖的，因此想和大家一同分享。",
        title: "老家-1",
        author: "陳立強",
      },
      {
        image: "/exhibits/老家-2.jpg",
        introduction:
          "本次展覽的四張照片為2024過年回去台南老家時所拍攝的作品，這幾年回去老家和外公外婆見面的頻率越來越低，因此格外珍惜和期待能夠相處的時光。\n\n這趟旅程實際上拍攝了一系列的照片，而之所以選擇此四張照片作為展出，是因為當下彷彿有一股難以名狀的能量指引我按下快門，我想是屬於台南老家的氣味、溫度、光影使我掉進了回憶的漩窩，想起了小時候在此處生長的點點滴滴，這些經驗思緒對我的人生是正向且溫暖的，因此想和大家一同分享。",
        title: "老家-2",
        author: "陳立強",
      },
      {
        image: "/exhibits/老家-3.jpg",
        introduction:
          "本次展覽的四張照片為2024過年回去台南老家時所拍攝的作品，這幾年回去老家和外公外婆見面的頻率越來越低，因此格外珍惜和期待能夠相處的時光。\n\n這趟旅程實際上拍攝了一系列的照片，而之所以選擇此四張照片作為展出，是因為當下彷彿有一股難以名狀的能量指引我按下快門，我想是屬於台南老家的氣味、溫度、光影使我掉進了回憶的漩窩，想起了小時候在此處生長的點點滴滴，這些經驗思緒對我的人生是正向且溫暖的，因此想和大家一同分享。",
        title: "老家-3",
        author: "陳立強",
      },
      {
        image: "/exhibits/老家-4.jpg",
        introduction:
          "本次展覽的四張照片為2024過年回去台南老家時所拍攝的作品，這幾年回去老家和外公外婆見面的頻率越來越低，因此格外珍惜和期待能夠相處的時光。\n\n這趟旅程實際上拍攝了一系列的照片，而之所以選擇此四張照片作為展出，是因為當下彷彿有一股難以名狀的能量指引我按下快門，我想是屬於台南老家的氣味、溫度、光影使我掉進了回憶的漩窩，想起了小時候在此處生長的點點滴滴，這些經驗思緒對我的人生是正向且溫暖的，因此想和大家一同分享。",
        title: "老家-4",
        author: "陳立強",
      },
      {
        image: "/exhibits/電晶體.jpg",
        introduction: "電晶體做成的101煙火",
        title: "電晶體做成的101煙火",
        author: "劉致為 教授",
      },
    ],
  },
  {
    type: "Drawings",
    images: [
      {
        image: "/exhibits/東方三聖.jpg",
        introduction:
          "在東方淨琉璃世界，\n日光和月光二位菩薩是藥師如來的左右脅侍。\n\n《藥師經》曰：「於其國中，有二菩薩摩訶薩：\n一名日光遍照，二名月光遍照，是無量無數菩薩之上首。」\n(三件組2018年作品，2019年月曾於台北市議會展出)\n\n- 日光菩薩(右)\n代表智慧，普照世間、破除黑暗，日放千光，普潤眾生，遍照天下。\n\n- 月光菩薩(左)\n代表靜定，黑夜中清涼寧靜的月光，映現明澈清輝，容攝大千。",
        title: "東方三聖",
        author: "向誼庭",
      },
      {
        image: "/exhibits/水彩七連.jpg",
        introduction: "Giraffes",
        title: "水彩七連",
        author: "楊珩",
      },
      {
        image: "/exhibits/水彩七連-2.jpg",
        introduction: "Ancestor",
        title: "水彩七連-2",
        author: "楊珩",
      },
      {
        image: "/exhibits/水彩七連-3.jpg",
        introduction: "London",
        title: "水彩七連-3",
        author: "楊珩",
      },
      {
        image: "/exhibits/水彩七連-4.jpg",
        introduction: "Fraumünster | Zürich | Schweiz",
        title: "水彩七連-4",
        author: "楊珩",
      },
      {
        image: "/exhibits/水彩七連-5.jpg",
        introduction: "Tranquility",
        title: "水彩七連-5",
        author: "楊珩",
      },
      {
        image: "/exhibits/水彩七連-6.jpg",
        introduction: "Matterhorn --Switzerland",
        title: "水彩七連-6",
        author: "楊珩",
      },
      {
        image: "/exhibits/水彩七連-7.png",
        introduction: "Zebra",
        title: "水彩七連-7",
        author: "楊珩",
      },
      {
        image: "/exhibits/碼.jpg",
        introduction:
          "獨自坐在桌前，與未接觸過的程式元素共舞，\n看著被 Swift 打破的 Windows、\n眼前啜飲一口 Java 的 Linux、\n在窗外虎視眈眈的 Postgres，\n自己一無所知，卻無所畏懼、勇往直前。",
        title: "碼",
        author: "吳柏均",
      },
    ],
  },
  {
    type: "Calligraphy",
    images: [
      {
        image: "/exhibits/茶生高山上.jpg",
        introduction:
          "茶生高山上，日月霧薰染。\n露重枝芽潤，風清氣出塵。\n塵石降火氣，兩松排污濁。 \n三巡人未醉，禪話特別多。",
        title: "茶生高山上",
        author: "謝銘倫",
      },
    ],
  },
  {
    type: "Interactive",
    images: [
      {
        image: "/exhibits/不要動我章魚.jpg",
        introduction:
          "《不要動我章魚》鼓勵觀眾深思人與他人之間的界限。\n藝術家以碎裂的章魚形象，\n寓意個體在現實中的脆弱與破碎。\n觀眾置身其中，被禁止觸碰，\n彷彿面對自我與他者間的隔閡與衝突。",
        title: "不要動我章魚",
        author: "許蘊琰",
      },
      {
        image: "/exhibits/弦之鏡.png",
        introduction:
          "由無數聯繫交織而成的，複雜的你。歡迎留下影跡。\n 連結：https://tingwoo.github.io/Strings-Expo/",
        title: "弦之鏡",
        author: "張庭梧",
      },
      {
        image: "/exhibits/弦之鏡-2.png",
        introduction:
          "由無數聯繫交織而成的，複雜的你。歡迎留下影跡。\n 連結：https://tingwoo.github.io/Strings-Expo/",
        title: "弦之鏡-2",
        author: "張庭梧",
      },
      {
        image: "/exhibits/stargunonline.png",
        introduction:
          "置身星際，與機器人對戰\n模式：循環生存 \n 下載連結：https://iiil.io/jDcS",
        title: "star gun online",
        author: "謝銘倫,林昀萱,劉育成",
      },
      {
        image: "/exhibits/stargunonline2.png",
        introduction:
          "置身星際，與機器人對戰\n模式：循環生存 \n 下載連結：https://iiil.io/jDcS",

        title: "star gun online-2",
        author: "謝銘倫,林昀萱,劉育成",
      },
    ],
  },
];
